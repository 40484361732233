@import url('https://fonts.googleapis.com/css2?family=Baumans&display=swap');
@import url('https://api.fontshare.com/v2/css?f[]=satoshi@400,500,700&display=swap');

 
.App {
  text-align: center;
  font-family: 'Baumans', sans-serif;
  max-height: 100vh;
  overflow: hidden; /* Prevent scrolling */
}
.App-Privacy {
  text-align: center;
  font-family: 'Baumans', sans-serif; 
  overflow: hidden; /* Prevent scrolling */
}
 
.App-logo {
  max-height: 108px;
  max-width: 347.76;
  width: 100%;
  pointer-events: none;
}


.thank-you-message{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
}
@media (max-width: 768px) {
  .App-logo {
    max-height: 108px;
    max-width: 250px;
    width: 100%;
    pointer-events: none;
  }
}


 
 .formcontainer{
  width: 100%;
  display: flex;
  justify-content: space-between;
 }
.App-link {
  color: #61dafb;
}

.Copyrights { 
  color: #FFF;
text-align: center;
font-family: Satoshi;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
opacity: 0.6;
position: absolute;
bottom: 25px;
}
 
.App-header {
  background-color: #052119;
  background-image: url(/src/assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
   height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 80px 20px 20px 20px;
}

 
.App-header2 {
  background-color: #052119;
  background-image: url(/src/assets/images/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 80px 20px 20px 20px;
}


@media (max-width: 768px) {
  .App-header { 
    padding:   20px;
  }
}

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.email-signup {
  width: 100%;
  max-width: 573px;
  height: 62px;
  overflow: hidden;
  --border-width: 1px;
  --border-radius: 80px;
  background: linear-gradient(rgba(255, 255, 255, 0.80) 0%, rgba(255, 255, 255, 0.3) 100%);
  border: 0px; 
  position: relative;
  border-radius: var(--border-radius);
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items:center;
  padding: 0 6px;
}

.email-signup::before {
  content: "";
  display: block;
  height: calc(100% - (var(--border-width) * 2));
  width: calc(100% - (var(--border-width) * 2));
  background: rgb(9, 16, 18);
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}
.submit-button {
  padding: 15px 30px;
  width: 152px;
  height: 52px;
  font-size: 1rem;
  color: white;
  cursor: pointer;
  --border-width: 1px;
  --border-radius: 80px; 
  background: linear-gradient(180deg, #CAFAE9 0%, #98DFC3 50%, #1CA372 100%);
  position: relative; /* Set position to relative for pseudo-element positioning */ 
  border: none;
  border-radius: var(--border-radius); /* Apply the border-radius */
  overflow: hidden; /* Ensure any overflowing content is clipped */
  z-index: 1; /* Ensure content appears above the pseudo-element */
  box-sizing: border-box; /* Make sure padding doesn't affect the size */
}

.submit-button::before {
  content: "";
  display: block;
  height: calc(100% - (var(--border-width) * 2));
  width: calc(100% - (var(--border-width) * 2));
  background: var(--Gradient-2, linear-gradient(180deg, #00E794 0%, #009761 100%));
  position: absolute;
  top: var(--border-width);
  left: var(--border-width);
  border-radius: calc(var(--border-radius) - var(--border-width));
  z-index: -1;
}





.Heading {
  color: #FFF;
  text-align: center;
  font-family: Baumans;
  font-size: 72px;
  font-style: normal;
  max-width: 613px;;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 62px; /* 86.111% */
}
@media (max-width: 768px) {
  .Heading {
    font-size: 48px; /* Decrease font size */ 
    line-height: 50px; /* Adjust line height */
  }
}

.secondary { 
  color: #FFF;
text-align: center;
font-family: Satoshi;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 120% */
opacity: 0.6;
max-width:402px;
margin-bottom: 70px;
}
.secondary-for-thankyou { 
  color: #FFF;
text-align: center;
font-family: Satoshi;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 120% */
opacity: 0.6;
max-width:402px; 
}
  .email-input {
    padding: 15px 15px 15px 30px; 
    border-radius: 25px 0 0 25px;
    font-family: Satoshi;
    font-size: 1rem;
    width: 300px;
    outline: none;
    border: none;
    background: none;
    color: #fff;
  }

  .email-input::placeholder {
    color: #FFF;
font-family: Satoshi;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 112.5% */
opacity: 0.7;
  }
  
  /* Optionally, add hover or focus state styles */
  .email-input:focus::placeholder {
    color: #aaa; /* Change color on focus if desired */
  }

 .soctial-container{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 110px;
 }

 
@media (max-width: 768px) {
  .soctial-container{ 
    margin-top: 30px;
   }
}
 
.sotial-btn{
  background: none;
  border: none;
}

.sotial-btn img {
  width: 24px;
  height: 24px;
}

.sotial-btn img {
  transition: transform 0.3s ease, filter 0.3s ease;
}

.sotial-btn:hover img {
  transform: scale(1.1);
  filter: brightness(1.2);
}
.note {
  margin-top: 20px;
  color: #FFF;
font-family: Satoshi;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 128.571% */
}

.note-container{
  background: linear-gradient(270deg, rgba(1, 227, 146, 0.00) 2.7%, rgba(1, 227, 145, 0.20) 49.87%, rgba(1, 227, 146, 0.00) 95.62%);
  height: 46px;
  display: flex;
  justify-content: center;
  align-items:center;
  margin-top: 20px;
  max-width: 595px;
  width: 100%;
}

.highlight {
  background: linear-gradient(229deg, #00E794 28.61%, #009761 112.43%);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Satoshi;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: 18px;
}


/* App.css */
@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.Heading {
  animation: fadeUp 1s ease-out;
  /* Other styles you have for .Heading */
}

.secondary {
  animation: fadeUp 1.4s ease-out;
}

.secondary-for-thankyou  {
  animation: fadeUp 1.4s ease-out;
}

.email-signup{
  animation: fadeUp 2s ease-out;
}

.note-container{
  animation: fadeUp 2.2s ease-out; 
}